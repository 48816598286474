import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";


// Call persistor.purge() to remove all persisted data


const environment = process.env.NODE_ENV;

const Endpoint = environment === "development" ? process.env.PUZZLE_DEV_API_URL || "https://dev.atpuzzle.com/api/v1/"  as
    string : process.env.PUZZLE_PROD_API_URL || "https://prod.atpuzzle.com/api/v1/" as
    string;

const instance: AxiosInstance = axios.create({
    baseURL: Endpoint,
    timeout: 500000,

    headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
    },

    transformRequest: [
        (data) => {
            return JSON.stringify(data);
        },
    ],
});

instance.interceptors.request.use((request) => {
    let cookie = localStorage.getItem("userInfo");

    if (cookie !== null) {
        const user = JSON.parse(localStorage.getItem("userInfo")!);
        const token = user.access_token;
        request.headers.Authorization = token ? `Bearer ${token}` : undefined;
        return request;
    } else {
        return request;
    }
});

// Add a request interceptor
const responseBody = (response: AxiosResponse) => response.data;

// const mainRemovePersistedData = () => removePersistedData;

const error = (response: AxiosError) => {
    if (response.response?.data === "unauthorized access") {
        // mainRemovePersistedData();
        localStorage.removeItem("persist:root");
        localStorage.removeItem("userInfo");
        window.location.href = `/auth/admin/login?redirect=${window.location.pathname}&&SessionExpire=true`;
    }

    if (response.response?.data === "Ivalid Token") {
        // mainRemovePersistedData();

        localStorage.removeItem("persist:root");
        localStorage.removeItem("userInfo");
        window.location.href = `/auth/admin/login?redirect=${window.location.pathname}&&SessionExpire=true`;
    }

    if (response.response?.data === "token not found") {
        // mainRemovePersistedData();

        localStorage.removeItem("persist:root");
        localStorage.removeItem("userInfo");
        window.location.href = `/auth/admin/login?redirect=${window.location.pathname}&&SessionExpire=true`;
    }

    if (response?.response?.status === 401) {
        // mainRemovePersistedData();
        localStorage.removeItem("persist:root");
        localStorage.removeItem("userInfo");
        window.location.href = `/auth/admin/login?redirect=${window.location.pathname}&&SessionExpire=true`;
    }

    throw response;
};

const requests = {
    get: (url: string, body?: object) => instance.get(url, {params: body}).then(responseBody, error),

    delete: (url: string, body?: object) => instance.delete(url, body).then(responseBody, error),

    post: (url: string, body: object) => instance.post(url, body).then(responseBody, error),

    put: (url: string, body: object) => instance.put(url, body).then(responseBody, error),

    patch: (url: string, body: object) => instance.patch(url, body).then(responseBody, error),
};

export default requests;
